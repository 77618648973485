import { userAxios } from '@/services'

const scheduleList = async (perPage) => {
    try {
        return await userAxios.get(`tutorial/index/${perPage}?type_id=2`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const scheduleFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`tutorial/filter/${perPage}?type_id=2`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    scheduleList,
    scheduleFilter
}