<template>
  <div>
    <!-- faq search section -->
    <section id="faq-search-filter">
      <b-card
        no-body
        class="faq-search"
        :style="{
          backgroundImage: `url(${require('@/assets/images/banner/banner.png')})`,
          height: '20vh',
        }"
      >
        <b-card-body class="text-center banner-faq" style="padding: 1rem !important;">
          <h2 class="text-primary">Tutorials</h2>
          <b-card-text class="mb-2">
            or choose a category to quickly find the help you need
          </b-card-text>

          <!-- form -->
          <b-form class="faq-search-input">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="searchbar"
                v-model="faqSearchQuery"
                placeholder="Search faq..."
              />
            </b-input-group>
          </b-form>
          <!-- form -->
        </b-card-body>
      </b-card>
    </section>
    <!--/ faq search section -->

    <!-- frequently asked questions tabs pills -->
    <section id="faq-tabs">
      <b-tabs
        vertical
        content-class="col-12 col-md-8 col-lg-9"
        pills
        nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
        nav-class="nav-left"
      >
        <!-- payment tab -->
        <b-tab
          v-for="(categoryObj, categoryName, index) in tutorials"
          :key="categoryName"
          :active="!index"
        >
          <!-- title -->
          <template #title>
            <!-- <feather-icon
              :icon="categoryObj.icon"
              size="18"
              class="mr-1"
            /> -->
            <span class="font-weight-bold">{{ categoryObj.name }}</span>
          </template>
          <faq-question-answer
            :text="{
              title: categoryObj.name,
              subtitle: categoryObj.description,
            }"
            :options="categoryObj.items"
          />
          <!-- <div class="mt-2">
            <div
              v-for="itemTutorial in categoryObj.items.data"
              :key="itemTutorial.id"
            >
              <faq-question-answer :options="itemTutorial" />
            </div>
          </div> -->
        </b-tab>
        <!--/ payment tab -->

        <!-- sitting lady image -->
        <template #tabs-end>
          <b-img
            fluid
            :src="require('@/assets/images/illustration/faq-illustrations.svg')"
            class="d-none d-md-block mt-auto"
          />
        </template>
        <!--/ sitting lady image -->
      </b-tabs>
    </section>
    <!--/ frequently asked questions tabs pills -->
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BForm,
  BInputGroup,
  BFormInput,
  BCardText,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BImg,
  BRow,
  BCol,
  BAvatar,
} from "bootstrap-vue";
import FaqQuestionAnswer from "./FaqQuestionAnswer.vue";
import axiosS from "@/services/resource/schedule";

export default {
  components: {
    BForm,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BInputGroup,
    BFormInput,
    BCardText,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BImg,
    FaqQuestionAnswer,
  },
  data() {
    return {
      faqSearchQuery: "",
      faqData: {},
      tutorials: [],
      setTimeoutBuscador: null,
    };
  },
  watch: {
    faqSearchQuery(){
      this.fetchData()
    }
  },
  mounted() {
    this.getSchedule();
  },
  methods: {
    getSchedule() {
      axiosS.scheduleList(10).then(({ registro: { data } }) => {
        this.tutorials = data;
      });
    },
    fetchData() {
      const dataFilter = {
        name: this.faqSearchQuery,
      };
      clearTimeout(this.setTimeoutBuscador);
      this.setTimeoutBuscador = setTimeout(() => {
        axiosS.scheduleFilter(10, dataFilter).then(({ data }) => {
          this.tutorials = data;
        });
      }, 400);
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-faq{
  padding: 0 !important;
}
@import "@core/scss/vue/pages/page-faq.scss";
</style>
